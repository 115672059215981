export const
  MODAL_OPEN = 'MODAL_OPEN',
  MODAL_CLOSE = 'MODAL_CLOSE',
  GET_PAGES = 'GET_PAGES',
  GET_ROUTER = 'GET_ROUTER',
  GET_EVENTS = 'GET_EVENTS',
  TOGGLE_SCROLL = 'TOGGLE_SCROLL',
  GET_SETTINGS = 'GET_SETTINGS',
  GET_NEWSLETTERS = "GET_NEWSLETTERS",
  FETCH_CLUBS = 'FETCH_CLUBS';
